<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
    <div v-if="scheduleInterviewDialog">
      <v-dialog
        max-width="1020px"
        width="90%"
        class="cdz"
        v-model="scheduleInterviewDialog"
        center
      >
        <ScheduleInterView
          @closeDialog="manageDialog"
          :userData="currentUserDataForScheduleInterview"
        />
      </v-dialog>
    </div>

    <v-card
      height="9.6vh"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="pl-0 ml-0">
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="$router.push('/campaign')"
        >
          Campaigns
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <p class="breadcrumb-text" style="margin-top: 2px">{{ campaignName }}</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important"
          >mdi-chevron-down</v-icon
        >
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <v-hover>
            <img
              @click="notificationDialog = true"
              class="cursor"
              src="../assets/bell 1.svg"
            />
          </v-hover>
        </v-badge>

        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8 pad">
      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div
                @click="clearFilter"
                class="text-body1 font-weight-bold black--text cursor"
              >
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab class="black--text justify-start" active-class="activeTab"
                >Clusters
                <span v-if="selectedClusterFilters.length > 0" class="ml-1"
                  >({{ selectedClusterFilters.length }})</span
                >
              </v-tab>
              <v-tab class="black--text justify-start" active-class="activeTab"
                >Boards
                <span v-if="selectedBoardsFilter.length > 0" class="ml-1"
                  >({{ selectedBoardsFilter.length }})</span
                >
              </v-tab>
            </div>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedClusterFilters"
                    active-class="secondary"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(clusterType, index) in clusters"
                      :key="index"
                      :value="clusterType.name"
                      elevated
                    >
                      {{ clusterType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedBoardsFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(boardType, index) in boardsData"
                      :key="index"
                      :value="boardType.name"
                      elevated
                    >
                      {{ boardType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-0">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                class="accent--text pa-4"
                @click="filterDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                :disabled="
                  selectedClusterFilters.length == 0 &&
                  selectedBoardsFilter.length == 0
                "
                :loading="isFilterLoading"
                rounded
                class="accent pa-4"
                @click="
                  (appliedFilter = true),
                    fetchCampaignAudience(),
                    getFilterCount()
                "
                >Apply</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <!-- QR Code Dialog -->
      <v-dialog v-model="qrDialog" class="cdz" persistent max-width="500px">
        <div v-if="qrDialog">
          <v-card>
            <v-container fluid class="pa-8">
              <v-icon size="24" class="pt-4" @click="qrDialog = false"
                >mdi-close</v-icon
              >

              <v-card-text
                class="text-center d-flex flex-column justify-center align-center"
              >
                <div ref="qrDownloadRef" style="margin: 8px; padding: 16px">
                  <p class="text-h5 py-1 mx-1" style="font-weight: bold">
                    {{ campaignName }}
                  </p>
                  <VueQRCodeComponent
                    style="
                      display: flex;
                      justify-content: center;
                      margin-bottom: 8px;
                      padding: 16px;
                    "
                    :text="qrCampaignLink"
                    error-level="L"
                  ></VueQRCodeComponent>
                </div>
                <v-btn
                  class="accent"
                  large
                  width="157px"
                  rounded
                  :loading="qrDownloadLoading"
                  @click="downloadQrCode"
                  >Download</v-btn
                >
              </v-card-text>
            </v-container>
          </v-card>
        </div>
      </v-dialog>

      <!-- Notification Dialog -->
      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>

      <v-dialog v-model="leagueDialog" class="cdz" max-width="500px">
        <v-card
          rounded="0"
          elevation="0"
          color="#D8D5C3"
          class="d-flex flex-row justify-space-between"
        >
          <v-card-title class="font-weight-bold">League</v-card-title>
          <v-icon @click="leagueDialog = false" class="mr-4">mdi-close</v-icon>
        </v-card>
        <v-card rounded="0" elevation="0">
          <v-card-title class="font-weight-medium">{{
            this.userName
          }}</v-card-title>
          <v-card-title class="text-body-1 font-weight-medium py-0">
            <v-row>
              <v-col cols="3">League</v-col>
              <v-col cols="2">Rank</v-col>
              <v-col cols="4">Subject</v-col>
              <v-col cols="3">Percentile</v-col>
            </v-row></v-card-title
          >
        </v-card>
        <v-card rounded="0" class="pb-2" elevation="0">
          <v-card-title
            v-for="(skill, index) in this.coreSkillsList"
            :key="index"
            class="text-body-1 font-weight-regular"
          >
            <v-row>
              <v-col cols="3">
                <div class="d-flex flex-column ml-3">
                  <img height="23px" width="25" :src="skill.leagueValueIcon" />
                </div>
              </v-col>
              <v-col cols="2">
                <div class="d-flex flex-column ml-1">#{{ skill.rank }}</div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex flex-column">
                  {{ skill.skillName }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex flex-column ml-4">
                  {{ skill.percentile.toFixed(2) }}%
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-dialog>

      <v-dialog v-model="restartAssessmentDialog" max-width="380px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="p-6">
            <v-card-text class="text-center">
              <p class="text-h5 pb-0">Restart Assessment
              </p>
              <p
                class="text-disabled grey--text text-subtitle-1 pt-3"
                color="rgba(0, 0, 0, 0.6)"
                disabled>
                Do you want to restart the assessment?
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn
                  depressed
                  class="btn-color--text"
                  large
                  width="157px"
                  outlined
                  rounded
                  @click="restartAssessmentDialog = false;"
                  >CANCEL</v-btn>
                <v-btn
                  class="accent white--text"
                  depressed
                  large
                  width="157px"
                  rounded
                  :loading="restartAssessmentLoader"
                  @click="clearUserAssessment(userForClearAssessment)"
                  >RESTART
                </v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- <v-dialog v-model="proctorDialog" fullscreen persistent>
        <v-card class="proctorCard" v-if="proctorDialog">
          <v-tabs color="green accent-4" left>
            <v-tab>Cumulative</v-tab>
            <v-tab>Timeline</v-tab>


            <v-tab-item :key="1">
              <v-container fluid>
                <div class="procBubChart">
                  <GChart v-if="proctorDialog" type="BarChart" style="height: 100%; width: 100%;"
                    :data="cumulativeProctoringData" :options="barChartOptions" />
                </div>
              </v-container>
            </v-tab-item>

            <v-tab-item :key="2">
              <v-container fluid>

                <div class="procBubChart">
                  <GChart v-if="proctorDialog" type="BubbleChart" style="height: 100%; width: 100%;"
                    :data="bubbleChartData" :options="bubbleChartOptions" />
                </div>

              </v-container>
            </v-tab-item>
          </v-tabs>
          <div class="w-100 d-flex justify-end px-4">
            <v-btn class="accent rounded-pill elevation-0 mx-4" @click="proctorDialog = false">
              Close
            </v-btn>
          </div>
        </v-card>

      </v-dialog> -->

      <!-- <v-dialog v-model="reportDialog" fullscreen class="cdz">

        <v-card>
          <div style="position: sticky; top: 0; z-index: 2; background: white">
            <v-card elevation="0" color="#D8D5C3" class="d-flex flex-row justify-space-between px-3">
              <v-card-title class="font-weight-bold">{{
                selectedUserName
              }}</v-card-title>
              <div>
                <v-btn @click="goBack" outlined elevation="0" class="accent--text mr-4 mt-3" rounded>Close</v-btn>
                <v-btn @click="generateReport" :loading="downloadLoader" elevation="0" class="accent mt-3"
                  rounded>Download</v-btn>
              </div>
            </v-card>
            <div class="py-0 mx-4 d-flex flex-column justify-space-between viewTestProctoringBox">
              <div class="py-0 mx-4 d-flex flex-row justify-space-between">
                <div class="d-flex flex-row align-center py-0 pr-0">
                  <v-card-title class="pl-0"><b>Total Score-</b></v-card-title>
                  <div class="d-flex flex-row ml-1">
                    <v-card-title class="px-0"><b>({{ totalScore }} / {{ maxMarks }})</b></v-card-title>
                  </div>
                </div>

                <div v-if="isSelect === 1" class="d-flex flex-row">
                  <v-card-title class="px-0"><b>Rank- #</b></v-card-title>
                  <v-card-title class="px-0"><b>{{ this.selectedSkill.rank }}</b></v-card-title>
                </div>
              </div>
              <div class="py-0 pb-4 mx-4 d-flex flex-row justify-space-between">
                <p>Screenshot Attempted : {{ proctoringData.Screenshot }}</p>
                <p>
                  Exit Full Screen - {{ proctoringData["Exit Fullscreen"] }}
                </p>
                <p>
                  Screen Change - {{ proctoringData["Switch Tab/Browser"] }}
                </p>
                <p>Key Stroke Attempted : {{ proctoringData["Copy/Paste"] }}</p>
                <div style="display: flex;">
                  <p><b>Proctoring Data </b></p>
                  <v-icon @click="showProc" color="#864f20">mdi-information</v-icon>
                </div>
              </div>
            </div>


            <div class="w-100 d-flex flex-row align-center justify-space-between py-0 my-0">

              <div>
                <div class="d-flex flex-row align-center py-0 mb-3 mt-0">
                  <p class="mx-4 px-0">
                    {{ selectedSkill.skillName }} - <b>({{ selectedSkill.score }}/{{ selectedSkill.maximumMarks }})</b>
                  </p>
                </div>

                <div class="d-flex flex-row ml-4">
                  <div class="text-body-2">Time Taken -</div>
                  <div class="text-subtitle-2 pl-2"><b>({{ userTime }}/{{ maxTime }}) mins</b></div>

                </div>
              </div>

              <div class="d-flex flex-row mr-5">

                <v-card width="280px" class="mr-4 elevation-0 transparent mt-1">

                  
                  <v-progress-linear v-model="progressLevel" rounded :color="progressLevel < 40 ? 'red' : 'green'"
                    height="10" style="position: relative"></v-progress-linear>


                  <img src="../../public/svgs/PassingIcon.svg" alt="pass icon" style="position: absolute; left: 112px">
                </v-card>

                <div class="d-flex flex-row align-center mx-0 px-0">
                
                  <img class="mx-2" src="../../public/svgs/PassingIcon.svg" alt="pass reference icon">
                  <div class="text-body-2">Passing Score</div>
            
                </div>
              </div>

            </div>


            <v-row class="py-0 px-4 my-1 mx-0 flex-row align-center">

              <v-spacer></v-spacer>
              <v-btn-toggle @change="switchResult(isSelect)" v-model="isSelect" mandatory dense rounded>
                <v-btn width="100px" :class="isSelect == 0
                  ? 'btn-color white--text text-subtitle-2 '
                  : '#000000 btn-color--text text-subtitle-2'
                  ">
                  Screening
                </v-btn>
                <v-btn width="100px" :class="isSelect == 1
                  ? 'btn-color white--text ttext-subtitle-2'
                  : '#000000 btn-color--text text-subtitle-2'
                  ">
                  Mains
                </v-btn>
                <v-btn width="100px" :class="isSelect == 2
                  ? 'btn-color white--text ttext-subtitle-2'
                  : '#000000 btn-color--text text-subtitle-2'
                  ">
                  Demo
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </div>

          <div class="w-100 d-flex flex-row question-result-view pa-4">
            <v-card class="scroll-view w-18" height="calc(100vh - 312px)" id="myScroll" outlined elevation="0"
              style="position: sticky; top: 300px;">
              <v-list>
                <v-list-item class="" v-for="(skill, index) in selectedResult.skills" :key="index">
                  <v-btn depressed :color="currenrSkillIndex == index
                    ? skill.result == 'PASSED'
                      ? 'green'
                      : skill.result == 'FAILED'
                        ? 'red'
                        : ''
                    : skill.result == 'PASSED'
                      ? 'green'
                      : skill.result == 'FAILED'
                        ? 'red'
                        : 'grey'
                    " @click="getSelectedSkillData(skill, index)" outlined class="rounded-xl">{{ skill.skillName }} ({{
    skill.score }}/{{ skill.maximumMarks }})</v-btn>
                </v-list-item>
              </v-list>
            </v-card>

            <v-card class="scroll-view w-82" height="calc(100vh - 312px)" id="myScroll" elevation="0">
              <v-list>
                <v-list-item v-for="(item, index) in  selectedSkill.questions" :key="index">



                  <div class="w-100 d-flex flex-column">

                    <div class="d-flex flex-row w-100">
                      <div class="d-flex flex-column w-50">

                        <div class="d-flex flex-row">
                          <div>Q.{{ index + 1 }}</div>
                          <div class="pl-4">{{ item.questionStatement }}</div>
                        </div>

                        <v-row class="pl-8 pt-2 my-1 ml-2">
                          <v-chip v-if="item.difficultyLevel != 'NA'" small class="mr-2">{{ item.difficultyLevel
                          }}</v-chip>
                          <v-chip v-if="item.complexityLevel != 'NA'" small class="mr-2">{{ item.complexityLevel
                          }}</v-chip>
                          <v-chip v-if="item.knowledgeLevel != 'NA'" small class="mr-2">{{ item.knowledgeLevel }}</v-chip>
                          <v-chip v-if="item.level != 'NA'" small class="mr-2">{{ item.level }}</v-chip>
                        </v-row>

                        <v-layout wrap row class="pl-9 pt-2 ml-1">
                          <div class="pr-3">Options -</div>
                          <div v-for="(option, i) in item.questionOptions" :key="i">
                            <div :class="item.correctAnswer === option.optionKey
                              ? 'pr-3 mt-1 text-body-2 green--text'
                              : 'pr-3 mt-1 text-body-2'
                              ">
                              {{ option.optionKey }}. {{ option.optionValue }}
                            </div>
                          </div>
                        </v-layout>


                        <v-row class="pl-8 pt-2 ml-1">
                          <div class="pl-1 text-body-2">Time allocated -</div>
                          <div class="pl-1 text-subtitle-2">
                            {{ item.estimatedTime }} Sec
                          </div>
                          <v-spacer></v-spacer>
                          <div class="pl-1 text-body-2">
                            Avg time taken by other users -
                          </div>
                          <div v-if="(!item.averageTimeTaken)" class="pl-1 text-subtitle-2">NA</div>
                          <div v-else class="pl-1 text-subtitle-2">{{ item.averageTimeTaken.toFixed(2) }}</div>
                          <v-spacer></v-spacer>

                        </v-row>

                      </div>

                      <div class="w-25">
                        <PieChart
                          :pieData="[['Type', 'Percentage'], ['Correct', ((item.noOfCorrectAttempt / item.noOfAttempt) * 100)], ['Incorrect', item.noOfCorrectAttempt ? (100 - ((item.noOfCorrectAttempt / item.noOfAttempt) * 100)) : 100],]" />
                      </div>

                      <div class="w-25">
                        <TimeChart :timeData="[['Type', 'Time', { role: 'style' }],
                        ['Given', parseFloat(item.estimatedTime), '#9F84AE'],
                        ['Average', item.averageTimeTaken?.toFixed(2), '#AA69CE'],
                        ['Current', item.timeTaken?.toFixed(2), '#9F84AE'],]" />
                      </div>

                    </div>

                    <div class="d-flex flex-column w-100 ml-1">
                      <div class="px-0 pl-9 w-100 mt-4">
                        <v-divider></v-divider>
                      </div>

                      <div class="pl-9 pt-2 text-subtitle-2 w-100 d-flex flex-row">

                        <div>Response Analytics -

                          <span v-if="(!item.myAnswer)" class="mr-6">Not Attempted</span>
                          <span v-else class="mr-6">Attempted</span>

                        </div>

                        <div>Answered - </div>

                        <div v-if="(!item.myAnswer)" class="mr-6">Skipped</div>
                        <div class="mr-6" v-else :class="item.correctAnswer === item.myAnswer
                          ? 'pl-1 green--text'
                          : 'pl-1 red--text'
                          ">
                          {{
                            item.correctAnswer === item.myAnswer
                            ? item.myAnswer + " (Correct)"
                            : item.myAnswer + " (Incorrect)"
                          }}
                        </div>

                        <div>Time taken -</div>
                        <div v-if="item.timeTaken != null" class="pl-1">
                          {{ item.timeTaken.toFixed(2) }} Sec
                        </div>
                      </div>


                      <div class="my-3 customDivider"></div>
                    </div>

                  </div>


                </v-list-item>
              </v-list>
            </v-card>


          </div>
        </v-card>
      </v-dialog> -->

      <!-- <v-dialog v-model="demoDialog" fullscreen transition="dialog-bottom-transition">

        <div class="demoVis">
          <DemoReport @close-demo="closeDemo" />
        </div>

      </v-dialog> -->

      <!-- <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" filename="user-report" :pdf-quality="2" :manual-pagination="true"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf"
        @progress=onProgress($event)>
        <section slot="pdf-content">
          <v-card class="w-85" height="100%" elevation="0">
            <v-list>
              <v-list-item v-for="( item, index ) in  selectedSkill.questions " :key="index">
                <v-col>
                  <v-row>
                    <div>Q.{{ index + 1 }}</div>
                    <div class="pl-4">{{ item.questionStatement }}</div>
                  </v-row>
                  <v-row class="pl-8 pt-2">
                    <v-chip v-if="item.difficultyLevel != 'NA'" small class="mr-2">{{ item.difficultyLevel }}</v-chip>
                    <v-chip v-if="item.complexityLevel != 'NA'" small class="mr-2">{{ item.complexityLevel }}</v-chip>
                    <v-chip v-if="item.knowledgeLevel != 'NA'" small class="mr-2">{{ item.knowledgeLevel }}</v-chip>
                    <v-chip v-if="item.level != 'NA'" small class="mr-2">{{ item.level }}</v-chip>
                  </v-row>
                  <v-layout wrap row class="pl-9 pt-2">
                    <div class="pr-3">Options -</div>
                    <div v-for="( option, i ) in  item.questionOptions " :key="i">
                      <div :class="item.correctAnswer === option.optionKey
                        ? 'pr-3 mt-1 text-body-2 green--text'
                        : 'pr-3 mt-1 text-body-2'
                        ">
                        {{ option.optionKey }}. {{ option.optionValue }}
                      </div>
                    </div>
                  </v-layout>
                  <v-row class="pl-8 pt-2">
                    <div class="pl-1 text-body-2">Time allocated -</div>
                    <div class="pl-1 text-subtitle-2">
                      {{ item.estimatedTime }} Sec
                    </div>
                    <v-spacer></v-spacer>
                    <div class="pl-1 text-body-2">
                      Avg time taken by other users -
                    </div>
                    <div v-if="(!item.averageTimeTaken)" class="pl-1 text-subtitle-2">NA</div>
                    <div v-else class="pl-1 text-subtitle-2">{{ item.averageTimeTaken.toFixed(2) }}</div>
                    <v-spacer></v-spacer>
                    <div class="pl-1 text-body-2">
                      People answered correctly -
                    </div>
                    <div v-if="(!item.noOfAttempt) && (!item.noOfCorrectAttempt)" class="pl-1 text-subtitle-2">NA</div>
                    <div v-else-if="(!item.noOfCorrectAttempt) && (item.noOfAttempt)" class="pl-1 text-subtitle-2">0%
                    </div>
                    <div v-else class="pl-1 text-subtitle-2">{{ (item.noOfCorrectAttempt / item.noOfAttempt) * 100 }}%
                    </div>
                  </v-row>
                  <v-row class="px-0 pl-9">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row class="pl-9 pt-2 text-subtitle-2">

                    <div>Response Analytics -

                      <span v-if="(!item.myAnswer)">Not Attempted</span>
                      <span v-else>Attempted</span>

                    </div>
                  </v-row>
                  <v-row class="pl-9 text-body-2">
                    <div>Answered - </div>
                    <div v-if="(!item.myAnswer)"> Skipped</div>
                    <div v-else :class="item.correctAnswer === item.myAnswer
                      ? 'pl-1 green--text'
                      : 'pl-1 red--text'
                      ">
                      {{
                        item.correctAnswer === item.myAnswer
                        ? item.myAnswer + " (Correct)"
                        : item.myAnswer + " (Incorrect)"
                      }}
                    </div>

                  </v-row>
                  <v-row class="pl-9 text-body-2">
                    <div>Time taken -</div>
                    <div v-if="item.timeTaken != null" class="pl-1">
                      {{ item.timeTaken.toFixed(2) }} Sec
                    </div>
                  </v-row>
                  <v-row class="px-0 pl-9">
                    <v-divider></v-divider>
                  </v-row>
                </v-col>
              </v-list-item>
            </v-list>
          </v-card>
        </section>
      </VueHtml2pdf> -->

      <v-container
        v-if="this.campaignType == 'TEACHER'"
        class="funnel-contianer pl-0 pr-3 mr-0"
        fluid
      >
        <div class="w-100 d-flex flex-row">
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  TEACHERS
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Registered/Invited</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="invited" propertyEnd="mainsStarted" funnelColor="#E0ED4B"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.invited -
                          chartData.mainsStarted,
                        chartData.invited
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <!-- <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  SCREENING
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Attempted</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.screeningStarted -
                          chartData.screeningPassed,
                        chartData.screeningStarted
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  SCREENING RESULT
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 green--text"
                  >Passed</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.screeningPassed -
                          chartData.mainsStarted,
                        chartData.screeningPassed
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div> -->
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  MAINS
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Attempted</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.mainsStarted -
                          chartData.mainsPassed,
                        chartData.mainsStarted
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  MAINS RESULT
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 green--text"
                  >Passed</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                <!-- <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.mainsPassed -
                          chartData.demoSubmitted,
                        chartData.mainsPassed
                      )
                    }}%
                  </div>
                </div> -->
              </v-card-text>
            </v-card>
          </div>
         <!--  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  DEMO
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Submitted</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="demoSubmitted" propertyEnd="interviewSubmitted"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.demoSubmitted -
                          chartData.interviewSubmitted,
                        chartData.demoSubmitted
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div> -->
          <div class="rounded-lg px-0 py-0 pl-1 w-19">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pr-0 pl-0 pb-0">
                <p class="font-weight-bold pl-1 funnelText">CAMPAIGN SUMMARY</p>
              </v-card-title>

              <v-card
                outlined
                class="font-weight-medium pl-1 d-flex align-center rounded mx-3 my-2"
                width="80%"
                height="40px"
              >
                Teachers Passed : {{ apraisals }}
                <v-spacer></v-spacer>
                <!-- <v-icon>mdi-chevron-right</v-icon> -->
              </v-card>
              <!-- <v-card
                outlined
                class="font-weight-medium pl-1 d-flex align-center rounded mx-3 my-2"
                width="80%"
                height="40px"
              >
                Teachers on PIP : {{ pip }}
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-right</v-icon>
              </v-card>
              <v-card
                outlined
                class="font-weight-medium pl-1 d-flex align-center rounded mx-3 my-2"
                width="80%"
                height="40px"
              >
                Teachers on TBD : {{ tbd }}
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-right</v-icon>
              </v-card> -->
            </v-card>
          </div>
        </div>
        <!-- <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col> -->
      </v-container>
      <v-container
        v-if="this.campaignType == 'JOB_SEEKER'"
        class="funnel-contianer pl-0 pr-3 mr-0"
        fluid
      >
      <div class="w-100 d-flex flex-row">
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg pr-0 pl-0 cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1">
              <p class="py-0 my-0 pl-2 funnelText">
                JOB SEEKERS
              </p>
            </v-card-title>

            <v-card-text class="pt-0 pr-0 pl-0">
              <span class="pl-3 black--text"
                >Users Invited</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.invited -
                        chartData.screeningStarted,
                      chartData.invited
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">
                SCREENING
              </p>
            </v-card-title>

            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 black--text"
                >Attempted</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.screeningStarted -
                        chartData.screeningPassed,
                      chartData.screeningStarted
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">
                SCREENING RESULT
              </p>
            </v-card-title>
            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 green--text"
                >Passed</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.screeningPassed -
                        chartData.mainsStarted,
                      chartData.screeningPassed
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">MAINS</p>
            </v-card-title>


            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 black--text"
                >Attempted</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.mainsStarted -
                        chartData.mainsPassed,
                      chartData.mainsStarted
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">
                MAINS RESULT
              </p>
            </v-card-title>

            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 green--text"
                >Passed</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.mainsPassed -
                        chartData.demoSubmitted,
                      chartData.mainsPassed
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">DEMO</p>
            </v-card-title>

            

            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 black--text"
                >Submitted</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

              <!-- <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.demoSubmitted -
                        chartData.interviewSubmitted,
                      chartData.demoSubmitted
                    )
                  }}%
                </div>
              </div> -->
            </v-card-text>
          </v-card>
        </div>
        <div class="d-flex flex-column px-0 py-0 pl-1 w-20">
          <v-card
            @click="goToJobSeekersAppraisal()"
            class="cursor rounded-xl mt-1 col3-card cardBorder"
            outlined
            height="9.6vh"
            color="#4D695B"
          >
            <v-card-title class="text-subtitle-2">
              <v-row justify="space-between" class="pl-2 pt-1 white--text">
                <span>Recommended for Interview</span>
                <v-icon color="white">mdi-chevron-right</v-icon>
              </v-row>
            </v-card-title>

            <v-card-subtitle class="px-4 pt-3 white--text text-h6">
             {{ chartData.demoSubmitted }}
            </v-card-subtitle>
          </v-card>

          <v-card
            @click="goToJobSeekersInterviewed()"
            class="cursor rounded-xl mt-1 col3-card cardBorder"
            outlined
            color="#FB8C00"
            height="9.6vh"
          >
            <v-card-title class="text-subtitle-2">
              <v-row justify="space-between" class="pl-2 pt-1 white--text">
                <span>Job Seekers Interviewed</span>
                <v-icon color="white">mdi-chevron-right</v-icon>
              </v-row>
            </v-card-title>

            <v-card-subtitle class="px-4 pt-3 white--text text-h6">
              {{ chartData.interviewSubmitted }}
            </v-card-subtitle>
          </v-card>

          <v-card
          @click="goToRecommendedForHiring()"
          class="cursor rounded-xl mt-1 col3-card cardBorder"
          outlined
          height="9.6vh"
          color="#00000061"
        >
          <v-card-title class="text-subtitle-2">
            <v-row justify="space-between" class="pl-2 white--text">
              <div>
                <span class="no-space"
                  >Recommended for Hiring</span
                >
              </div>

              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-row>
          </v-card-title>

          <v-card-subtitle class="px-4 pt-3 white--text text-h6">
            {{ this.recommendedJobseekerCount }}
          </v-card-subtitle>
        </v-card>
        </div>
      </div>
        <!-- <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col> -->
      </v-container>

      <v-row justify="space-between" align="center" class="mb-2">
        <v-col cols="4" sm="4" md="4">
          <div class="text-h5">{{ campName }}</div>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex flex-row justify-end">
          <!-- <div class="w-50"> -->
          <v-btn
            v-if="campaignType.toLowerCase() !== 'teacher'"
            @click="copyToClipboard"
            text
            color="#1B72E8"
            ><v-icon>mdi-link-variant</v-icon></v-btn
          >

          <v-btn
            v-if="campaignType.toLowerCase() !== 'teacher'"
            :loading="qrLoading"
            @click="generateQRCode"
            text
            color="#1B72E8"
            ><v-icon>mdi-qrcode</v-icon></v-btn
          >

          <!-- </div> -->

          <v-card width="326px" class="elevation-0 transparent">
            <v-text-field
              solo
              label="Search here..."
              hide-details
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              class="search-bar border-button rounded-pill ml-4"
              dense
              clearable
            ></v-text-field>
          </v-card>
          <v-btn depressed rounded outlined class="border-button mx-3"
            ><v-icon>mdi-tune</v-icon>
            <span v-if="filterCount > 0" @click="filterDialog = true"
              >Filter ({{ filterCount }})</span
            >
            <span v-else @click="filterDialog = true">Filter</span>
          </v-btn>
          <v-btn
            depressed
            outlined
            rounded
            @click="downlaodCamapignUserDetails"
            class="elevation-0 border-button mx-3"
            :loading="downloadLoader"
            ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
          <v-btn
            class="primary mx-2 btn-color elevation-0"
            v-bind="attrs"
            v-on="on"
            rounded
            dark
            >View Schedule</v-btn
          >
        </v-col>
      </v-row>

      <!-- <v-data-table
      v-model="selected"
      :loading="dataTableLoading"
      loading-text="Loading Audience"
      :headers="headers"
      :items="users"
      show-select
      :single-select="singleSelect"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50, 100],
      }"
      :server-items-length="count"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        {{ item.personalInfo.firstName + " " + item.personalInfo.lastName }}
      </template>
      <template v-slot:[`item.INVITED`]="{ item }">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text class="text-subtitle-2 mr-5" @click="getResult(item)">
          View Result
        </v-btn>
      </template></v-data-table> -->
      <template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="itemsPerPage"
      :page.sync="currentPage"
      :options.sync="options"
      @update:options="updateOptions"
      :loading="dataTableLoading"
      :server-items-length="totalUsers"
      class="elevation-0 custom-data-table"
      :custom-filter="filterAllAudiances"
    >
  <template v-slot:header.stages>
  <v-row class="pl-4 mt-2">
    <v-col cols="12" class=" reportedText d-flex justify-space-between">
      <div v-for="(step, index) in stepperStages" :key="index" class="text-center" style="width: 90px">
        {{ step.label }}
      </div>
    </v-col>
  </v-row>
    </template>

    <template v-slot:header.league>
      <v-row class="text-center custom-header">
        <v-col class="header-cell">
          <span class="custom-header-text">League</span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:header.actions>
      <v-row class="text-center custom-header">
        <v-col class="header-cell">
          <span class="custom-header-text">Action</span>
        </v-col>
      </v-row>
    </template>



<template v-slot:item.stages="{ item, index }">
  <v-row class="d-flex flex-row align-center pl-15 pr-8">
    <v-col cols="12" class="d-flex justify-space-between">
      <v-row class="d-flex flex-row align-center">
        <!-- Loop through each step -->
        <div v-for="(step, stepIndex) in stepperStages" :key="stepIndex" class="text-center" style="width: 80px">
          <v-icon medium :color="stepper.get(index)[step.key].iconColor">
            {{ stepper.get(index)[step.key].name }}
          </v-icon>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

      <template v-slot:item.name="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ item.personalInfo.firstName }}
              {{ item.personalInfo.lastName }}
            </div>
          </template>
          <span v-if="item.userType == 'Teacher'">
            {{
              "Grade : " +
              item.responsibleTeachingAt[0].grade +
              ", " +
              "Level : " +
              item.responsibleTeachingAt[0].level +
              ", " +
              "Subject : " +
              item.responsibleTeachingAt[0].subject
            }}
          </span>
          <span v-else>
            {{
              "Levels : " +
              item.teachingInterests?.levels +
              ", " +
              "Subjects : " +
              item.teachingInterests?.subjects +
              ", " +
              "School : " +
              item.teachingInterests?.schools
            }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.email="{ item }">
        {{ item.personalInfo.contactInfo.email }}
      </template>

      <template v-slot:item.stages="{ item, index }">
        <v-row class="d-flex flex-row align-center pl-15 pr-8">

          <v-col cols="12" v-if="campaignType !== 'TEACHER'">
            <v-row class="d-flex flex-row align-center">
              <v-icon
                medium
                :color="stepper.get(index).invitation.iconColor"
              >{{ stepper.get(index).invitation.name }}</v-icon>

              <!-- Only hide the screening section if campaignType is 'TEACHER' -->
              <template v-if="campaignType !== 'TEACHER'">
                <v-divider
                  :thickness="10"
                  class="border-opacity-25 px-0 py-0"
                  :color="stepper.get(index).screening.iconColor"
                ></v-divider>
                <v-icon
                  medium
                  :color="stepper.get(index).screening.iconColor"
                >{{ stepper.get(index).screening.name }}</v-icon>
              </template>

              <!-- Mains section should be visible for both 'TEACHER' and other campaigns -->
              <v-divider
                :thickness="2"
                class="border-opacity-87 px-0 py-0"
                :color="stepper.get(index).mains.iconColor"
              ></v-divider>
              <v-icon
                medium
                :color="stepper.get(index).mains.iconColor"
              >{{ stepper.get(index).mains.name }}</v-icon>

              <!-- Demo and Interview steps only for non-teacher campaigns -->
              <v-divider v-if="campaignType !== 'TEACHER'"
                :thickness="10"
                class="border-opacity-25 px-0 py-0"
                :color="stepper.get(index).demo.iconColor"
              ></v-divider>
              <v-icon v-if="campaignType !== 'TEACHER'"
                medium
                :color="stepper.get(index).demo.iconColor"
              >{{ stepper.get(index).demo.name }}</v-icon>

              <v-divider v-if="campaignType !== 'TEACHER'"
                :thickness="10"
                class="border-opacity-25 px-0 py-0"
                :color="stepper.get(index).interview.iconColor"
              ></v-divider>
              <v-icon v-if="campaignType !== 'TEACHER'"
                medium
                class="pr-4"
                :color="stepper.get(index).interview.iconColor"
              >{{ stepper.get(index).interview.name }}</v-icon>
            </v-row>

          </v-col>
          <v-col v-else>
            <v-row class="d-flex flex-row align-center">
              <v-icon
                medium
                :color="stepper.get(index).invitation.iconColor"
              >{{ stepper.get(index).invitation.name }}</v-icon>

              <v-divider
                :thickness="2"
                class="border-opacity-87 px-0 py-0"
                :color="stepper.get(index).screening.iconColor"
              ></v-divider>
              <v-icon
                medium
                :color="stepper.get(index).screening.iconColor"
              >{{ stepper.get(index).screening.name }}</v-icon>

            </v-row>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.league="{ item }">
        <div v-if="item.coreSkillsSubject != undefined && item.coreSkillsSubject.length > 0" class="text-subtitle-1 d-flex justify-center">
          <div v-if="!item.NoLeague" class="d-flex flex-wrap justify-space-around pt-4">
            <div v-for="(coreSkill, index) in item.coreSkillsSubject.slice(0,3)" :key="index" class="w-fit">
              <v-tooltip v-if="coreSkill.league!='Not Eligible'" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="d-flex flex-column align-center mx-2">
                    <img v-if="coreSkill.league=='Gold'"
                    height="23px" width="25"
                    src="./../assets/gold.svg"/>
                    <img v-if="coreSkill.league=='Silver'"
                    height="23px" width="25"
                    src="./../assets/silver.svg"/>
                    <img v-if="coreSkill.league == 'Bronze' ||
                    coreSkill.league == 'Bonze'"
                    height="23px" width="25"
                    src="./../assets/bronze.svg"/>
                    <div style="font-size: 12px">
                      #{{ coreSkill.rank }}
                    </div>
                  </div>
                </template>
                <span style="font-size: 15px"
                  >{{ coreSkill.skillName }} -
                  {{ coreSkill.percentile.toFixed(2) }}%
                </span>
              </v-tooltip>
            </div>
            <v-btn v-if="item.coreSkillsSubject.length > 3" :loading="btnLoading" @click="
            openLeagueDialog(
              item.coreSkillsSubject,
              item.personalInfo.firstName,
              item.personalInfo.lastName
            )
            " height="10px" width="10px" text color="#1B72E8" class="text-body-2 ml-9">View More</v-btn>
          </div>
          <div v-else class="d-flex justify-center mt-1">
          -
          </div>
        </div>
        <div v-else class="d-flex justify-center mt-1" >
          -
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row 
          class="d-flex justify-space-between align-center" 
          :style="{ width: item.userType !== 'Teacher' ? '150px' : '150px' }"
        >
          <!-- Test Report Button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img 
                v-bind="attrs" 
                v-on="on" 
                @click="getResult(item)" 
                class="cursor mr-2" 
                width="24px"
                src="../assets/Live.svg" 
              />
            </template>
            <span>Test Report</span>
          </v-tooltip>
      
          <!-- User Report Button -->
          <v-tooltip bottom v-if="item.userType.toLowerCase() !== 'teacher'">
            <template v-slot:activator="{ on, attrs }">
              <img 
                v-bind="attrs" 
                v-on="on" 
                @click="getFinalResult(item.userId)" 
                class="cursor mr-2"
                width="24px" 
                :src="isUserReportEnabled(item) ? require('../assets/user_report.svg') : require('../assets/user_report_disabled.svg')"
              />
            </template>
            <span>User Report</span>
          </v-tooltip>
          
          <img 
            v-else 
            class="cursor mr-2" 
            width="24px" 
            :src="require('../assets/user_report_disabled.svg')" 
          />
          
      
          <!-- Restart Exam Button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img 
                v-bind="attrs" 
                v-on="on" 
                @click="restartAssessmentDialog=true; userForClearAssessment=item.userId" 
                class="cursor mr-2"
                width="24px" 
                src="../assets/restart.svg" 
              /> 
            </template>
            <span>Restart Exam</span>
          </v-tooltip>
      
          <!-- Schedule Interview Button -->
          <!-- <v-tooltip bottom v-if="item.userType !== 'Teacher'">
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                v-if="canScheduleInterview(item)"
                class="cursor mr-2"
                @click="scheduleInterview(item)"
                width="24px"
                src="./../assets/scheduleInterview.svg"
              />
              <img 
                v-else 
                class="mr-2" 
                width="24px" 
                src="./../assets/schedule.svg" 
              />
            </template>
            <span>Schedule Interview</span>
          </v-tooltip> -->
        <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <img
          v-bind="attrs"
          v-on="on"
          v-if="canScheduleInterview(item) && item.userType !== 'Teacher'"
          class="cursor mr-2"
          @click="scheduleInterview(item)"
          width="24px"
          src="./../assets/scheduleInterview.svg"
        />
        <img v-else class="mr-2" width="24px" src="./../assets/schedule.svg" />
      </template>
      <span>Schedule Interview</span>
    </v-tooltip>
        </v-row>
      </template>



    </v-data-table>
  </v-card>
</template>




      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="errorDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="successDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">Cluster</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="successDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="snackbar" :timeout="1000">
      {{ "Link Copied" }}
    </v-snackbar>
    <v-snackbar v-model="clearUserAssessmentSuccessSnackbar" :timeout="1000">
      Success
    </v-snackbar>
    <v-snackbar v-model="clearUserAssessmentFailureSnackbar" :timeout="1000">
      Application error encountered
    </v-snackbar>
  </div>
</template>
  
<script>
// import "abc" from "../../svgs/"
import FunnelChart from "./FunnelChart.vue";
// import FunnelChart1 from "./FunnelChart1.vue";
// import FunnelChart2 from "./FunnelChart2.vue";
// import FunnelChart3 from "./FunnelChart3.vue";
// import FunnelChart4 from "./FunnelChart4.vue";
import CampaignController from "@/controllers/CampaignController";
import AuthService from "../services/AuthService";
import Notification from "./Notification";
import ScheduleInterView from "../components/ScheduleInterView.vue";
import { mapActions } from 'vuex';
// import DemoReport from "./DemoReport";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
  where,
  query
} from "firebase/firestore";
import { db, app } from "../firebase";
import ClusterController from "@/controllers/ClusterController";
import BoardController from "@/controllers/BoardController";
import { GChart } from "vue-google-charts/legacy";
import FunnelChart1 from "./FunnelChart1.vue";
import FunnelChart2 from "./FunnelChart2.vue";
import FunnelChart3 from "./FunnelChart3.vue";
import FunnelChart4 from "./FunnelChart4.vue";
import FunnelChart5 from "./FunnelChart5.vue";
import FunnelChart6 from "./FunnelChart6.vue";
import FunnelChart7 from "./FunnelChart7.vue";
import FunnelChart8 from "./FunnelChart8.vue";
import FunnelChart9 from "./FunnelChart9.vue";
// import html2pdf from "html2pdf.js"
// import jsPDF from "jspdf"
// import VueHtml2pdf from "vue-html2pdf";
// import PieChart from "../components/ReportGraphs/PieChart.vue"
// import TimeChart from "../components/ReportGraphs/TimeChart.vue"

import axios from "axios";

import VueQRCodeComponent from "vue-qrcode-component";
import html2canvas from "html2canvas";
import ScheduleInterViewVue from "@/components/ScheduleInterView.vue";
import Papa from "papaparse";
import FunnelChartCommon from "./FunnelChartCommon.vue";
import { filter } from "jszip";

export default {
  name: "CampaignUserView",
  components: {
    // TimeChart,
    // PieChart,
    // VueHtml2pdf,
    ScheduleInterView,
    Notification,
    // DemoReport,
    FunnelChart,
    GChart,
    FunnelChart1,
    FunnelChart2,
    FunnelChart3,
    FunnelChart4,
    FunnelChart5,
    FunnelChart6,
    FunnelChart7,
    FunnelChart8,
    FunnelChart9,
    FunnelChartCommon,
    VueQRCodeComponent,
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 5,
      options: {},
      restartAssessmentLoader: false,
      restartAssessmentDialog: false,
      userForClearAssessment: "",
      progressLevel: 0,
      userTime: 0,
      maxTime: 0,
      maxMarks: 0,
      campName: "",
      audienceId: null,
      // dataDemoVideos: [],
      notificationDialog: false,
      appliedFilter: false,
      scheduleInterviewDialog: false,
      options: {},
      pageSize: 10,
      snackbar: false,
      totalUsers: 0,
      page: 1,
      isSelect: 0,
      count: 0,
      errorDialog: false,
      successDialog: false,
      errorMessage: "",
      singleSelect: false,
      dLoading: false,
      loading: false,
      selected: [],
      clusters: [],
      attrs: "",
      on: "",
      currentUserDataForScheduleInterview: {},
      downloadLoader: false,
      btnLoading: false,
      search: "",
      selectedClusterFilters: [],
      selectedBoardsFilter: [],
      filterDialog: false,
      filterCount: 0,

      id: "",
      uniqueArray: [],
      skillsList: [],
      power: 80,
      campaignName: "",
      schoolsList: [],
      users: [],
      preSearchUser: [],
      questions: [],
      totalScore: 0,
      boardsData: [],
      currenrSkillIndex: 0,
      reportDialog: false,
      demoDialog: false,
      color: "white",
      selectedSkill: {},
      userName: "",
      selectedResult: {},
      isFilterLoading: false,
      resultData: [],
      searchBool: false,
      coreSkillsList: [],
      preloader: false,
      dataTableLoading: true,
      isResudule: false,
      headers: [
      { text: 'Name', value: 'name',width: '20%', },
        { text: 'Email ID', value: 'email',width: '20%'  },
        { text: 'Stages', value: 'stages',width: '30%'  },
        { text: 'League', value: 'league',sortable: false, width: '20%'  },
        { text: 'Action', value: 'actions', sortable: false,width: '20%' },
      ],
      tableData: [],
      invited: 0,
      screeningStarted: 0,
      screeningPassed: 0,
      screeningFailed: 0,
      mainsStarted: 0,
      leagueDialog: false,
      mainsPassed: 0,
      mainsFailed: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0,
        apraisals: 0,
        pip: 0,
        tbd: 0,
        procBarColor: "#000",
      },
      selectedUser: "",
      proctorDialog: false,
      selectedUserName: "",
      stepper: new Map(),
      csvData: [],
      campaignDetails: {},
      recommendedJobseekerCount: 0,

      // bubbleChartOptions: {
      //   title: "",

      //   hAxis: {
      //     title: 'Time (min)', format: 0, viewWindow: {
      //       min: 0,
      //       max: 10
      //     }
      //   },
      //   vAxis: {
      //     title: 'Ques', format: 0, viewWindow: {
      //       min: 0,
      //       max: 10
      //     }
      //   },

      //   sizeAxis: {
      //     minSize: 5,
      //     maxSize: 5
      //   },

      //   // legend: 'none',
      // },

      // bubbleChartData: [
      //   ["Id", "Time (min)", "Ques", "Activity", "Time Stamp"],
      // ],

      // barChartData: this.cumulativeProctoringData,

      // barChartOptions: {
      //   title: '',
      //   titleTextStyle: {
      //     fontSize: 12
      //   },
      //   hAxis: {
      //     title: 'Count',
      //     format: 0,
      //     gridlines: {
      //       color: 'transparent'
      //     },
      //     viewWindow: {
      //       min: 0,
      //     },
      //   },
      //   vAxis: {
      //     title: 'Activity',
      //   },
      //   legend: 'none'
      // },

      // userName: "",
      snackbar: false,
      qrLoading: false,
      qrCampaignLink: "",
      qrDialog: false,
      qrDownloadLoading: false,
      clearUserAssessmentSuccessSnackbar: false,
      clearUserAssessmentFailureSnackbar: false
    };
  },
  watch: {

    currentCampaignId: {
      immediate: true,
      handler() {
        this.restorePageState()
      }
    },
    //   options: {
    //     handler() {
    //       console.log(this.options);
    //       this.pageSize = this.options.itemsPerPage;
    //       this.page = this.options.page;
    //       if(this.searchBool){
    //         this.searchData(this.search);
    //       }else{
    //       this.fetchAllClusters();
    //       }
    //     },
    //     deep: true,
    //   },
    options: {
      handler() {
        this.pageSize = this.options.itemsPerPage;
        this.page = this.options.page;
        this.users=[];
        this.isLoading=true
        this.fetchCampaignAudience();
      },
      deep: true,
    },

    // selectedClusterFilters(newValue) {
    //   if(newValue.length === 0) {
    //     this.selectedClusterFilters = []
    //     this.fetchCampaignAudience()
    //   } else {
    //     this.selectedClusterFilters = newValue
    //     this.fetchCampaignAudience()
    //   }
    // },

    // selectedBoardsFilter(newValue) {
    //   if(newValue.length === 0) {
    //     this.selectedBoardsFilter = []
    //     this.fetchCampaignAudience()
    //   } else {
    //     this.selectedBoardsFilter = newValue
    //     this.fetchCampaignAudience()
    //   }
    // },

    search(newValue) {
      this.page = 1
      this.currentPage = 1
      if (newValue == "" || newValue == null) {
        this.search = ""
        this.fetchCampaignAudience()
        // this.users = this.calculateUsersForLeague(this.preSearchUser);
        // this.addDemoInterviewScore()
        // this.createStepperData(this.users)
      } else {
        this.search = newValue;
        this.fetchCampaignAudience()
        // this.searchData(newValue);
        // this.addDemoInterviewScore()
        // this.createStepperData(this.users)
      }
    },
  },
  mounted() {
    this.restorePageState(this.id);
    this.campaignName = this.$route.query.campaignName;
    this.campaignType = this.$route.query.campaignType;
    this.schoolsList = this.$route.query.schoolsList;
    // console.log("data is", this.schoolsList);
    if (this.campaignType === 'TEACHER') {
      this.headers = this.headers.filter(header => 
        !['screening', 'demo', 'interview'].includes(header.value)
      );
    }
    console.log("id :", this.id, this.campaignName, this.campaignType);

    this.setupSnapshotListenerFunnel(this.id);
    this.recommendedJobseekerCountListener();
    // this.fetchCampaignAudience();

    // this.getDemoVideos();
  },
  computed: {
    currentCampaignId() {
      return this.$route.query.id;
    },
    stepperStages() {
      if (this.campaignType === 'TEACHER') {
        return [
          { label: 'Invited' },
          { label: 'Mains' },
        ];
      } else {
        return [
          { label: 'Invited' },
          { label: 'Screening' },
          { label: 'Mains' },
          { label: 'Demo' },
          { label: 'Interview' },
        ];
      }
    },
  },
  methods: {
     ...mapActions(['savePaginationState', 'clearPaginationStates']),
     updateOptions(newOptions) {
      this.savePaginationState({
        campaignId: this.currentCampaignId,
        paginationState: {
          page: newOptions.page,
          itemsPerPage: newOptions.itemsPerPage,
        }
      })
    },

    restorePageState() {
      const savedState = this.$store.state.campaignPaginationStates[this.currentCampaignId]
      if (savedState) {
        this.currentPage = savedState.page
        this.itemsPerPage = savedState.itemsPerPage
        this.options = { ...this.options, ...savedState }
      } else {
        this.resetToDefaultPagination()
      }
    },
    resetToDefaultPagination() {
      this.currentPage = 1
      this.itemsPerPage = 5 // or whatever your default is
      this.options = { ...this.options, page: 1, itemsPerPage: 5 }
    },


    canScheduleInterview(item) {
      return (this.$store.state.role?.interview?.admin ?? false) && (item.enableScheduleInterview);
    },
    isUserReportEnabled(item) {
      if (item.userType != 'Teacher') {
        return this.stepper.get(this.users.indexOf(item)).screening.iconColor == 'green' &&
               this.stepper.get(this.users.indexOf(item)).mains.iconColor == 'green' &&
               this.stepper.get(this.users.indexOf(item)).demo.iconColor == 'green' &&
               this.stepper.get(this.users.indexOf(item)).interview.iconColor == 'green';
      } else {
        // return this.stepper.get(this.users.indexOf(item)).screening.iconColor == 'green' &&
               this.stepper.get(this.users.indexOf(item)).mains.iconColor == 'green'
              //  this.stepper.get(this.users.indexOf(item)).demo.iconColor == 'green';
      }
    },
    snapPromise(ref){
      let data = []
     return new Promise((res)=> {
      onSnapshot(ref, (snap)=>{ 
          if(!snap.empty){
           snap.docs.map((doc)=> data.push(doc.data())) 
          }
          res(data)
        })
     })
    },
    getSubSorted(data){
      if(data?.length){
          return data.sort((a, b) => {
          const subA = a.subjectName.toUpperCase(); 
          const subB = b.subjectName.toUpperCase(); 
            if (subA < subB) {
            return -1;
          }
          if (subA > subB) {
            return 1;
          }

          return 0;
        })
      }
      return data

    }, 
    getCoreNonCoreSkill(data, core){
      if(data?.length){
        if(core){
          return data.filter(item => item.isCoreSkill)
        } else {
          return data.filter(item => !item.isCoreSkill)
        }
       
      }
    },
    getNonCoreObj(data){
      let obj = {};
      if(data?.length){
          data.forEach((item)=>{
              obj[item.subjectName] = item.score;
              obj[`${item.subjectName}_maximumMarks`] = item.maximumMarks
          })
      }
        return obj
    },
    getSceeningMainsStatusAndTime(campaignStatus, type){
      if(type == 'screening'){
        if(campaignStatus[2]){
          return campaignStatus[2]
        } else {
          return campaignStatus[1] || {status: '', time: ''}
        }
      } else {
         if(campaignStatus[4]){
          return campaignStatus[4]
         } else {
          return campaignStatus[3] || {status: '', time: ''}
         }
      }
     
    },
    getTeacherItems(items, key){
      return items.map(item => item[key]).join()
    },
    evalZeors(score = -1){
      let hasDecimal = (score - Math.floor(score)) !== 0;
      if(score >= 0){
        return  hasDecimal ? `${score}/10` :  `${score}.0/10`
      } 
      return 'NA'    
    }, 
    getRecommendation(coreSkills = {}, interviewFeedback = []){
     let recommendedSubjects = [];
     let notRecommendedSubjects = [];
     let onHoldSubjects = [];
     let interviewSubXmarks =  this.getInterveiwSubjectsAndPercent(interviewFeedback, coreSkills);
     coreSkills.forEach((subject)=>{
      let finalPercent = this.getFinalPercent(subject.percentile,  interviewSubXmarks.get(subject.subjectName))
      if(finalPercent > 0 && finalPercent < 40 ){
        notRecommendedSubjects.push(subject.subjectName)
      }else if (finalPercent >= 40 && finalPercent < 60){
          onHoldSubjects.push(subject.subjectName)
      } else if( finalPercent >=  60){
        recommendedSubjects.push(subject.subjectName)
      }
     })
       return {recommend:recommendedSubjects.join(), hold: onHoldSubjects.join(), notRecommended: notRecommendedSubjects.join()}
    },
   
    getInterveiwSubjectsAndPercent(interviewData, coreSkills){
      let  interviewSubMap = new Map();
            let subjects = coreSkills?.map(item => item.subjectName);
            subjects?.forEach((subject)=> {
                let subPercent = []
                interviewData?.forEach((interview)=> {
                    let subjectData = interview.subjects.filter((interviewsub)=> interviewsub.subjectName == subject)   
                    subPercent.push(this.ratingSum(subjectData[0].feedback) / subjectData[0].feedback.length * 100 / 10)
                })
               interviewSubMap.set(subject,  this.sumPercentValues(subPercent) / subPercent.length)
            })
       return interviewSubMap
        },
        ratingSum(feeback){
           return feeback.reduce((acc, cur)=> {
                return acc + cur.rating
            },0)
        },
        sumPercentValues(data){
          return data.reduce((acc, cur )=> acc+ cur,0)
        },
    getFinalPercent(systemPercent, interviewPercent){
       return  (systemPercent + interviewPercent) / 2;
    },
    getNetScore(data){
      let netScore = 0;
      if(data?.length){
        data?.forEach((skil) => {
            netScore = netScore + parseFloat(skil.finalNetScore);
          });
          netScore = netScore / data.length;
        return  netScore = !isNaN(netScore) ? netScore.toFixed(2) : 0.00;
      }
      return netScore
    },
    getMainsScoreOutOfTen(data = {}){
      let res = 0
      if(data.hasOwnProperty('score') && data.hasOwnProperty('maximumMarks')){
        res = ((parseFloat(data.score) / parseFloat(data.maximumMarks))*10).toFixed(1);
      } 
       return res !== 'NaN' ? res : '0'
    },
    getSubjectWiseDemoAvgScore(demoData = new Array(3).fill("")) {
      let res ={}
      demoData.length < 3 ? demoData.push(...new Array(3-demoData.length).fill("")) : ''
        demoData.forEach((item, index)=>{
            delete item.name
            const totalNumOfParams = Object.values(item)?.length
            res[`subject_${index+1}_demo_avg_score`] = item !== "" ? (Object.values(item).reduce((acc,cur) => acc+cur,0)/totalNumOfParams) : 'NA'
        })
      return res; 
    },
    getDemoScores(demoData = new Array(3).fill("")){
      let res = {}
      demoData.length < 3 ? demoData.push(...new Array(3-demoData.length).fill("")) : ''
        demoData.forEach((item, index)=> {
          res[`subject_${index+1}_Knowledge_Level_Score`] = item !== "" ? item?.knowledgeLevel || 'NA'  :'NA';
          res[`subject_${index+1}_Confidence_Level_Score`] = item !== "" ? item?.confidenceLevel || 'NA' : 'NA';
          res[`subject_${index+1}_Behavior_Level_Score`] = item !== "" ? item?.behaviorlLevel || 'NA' : 'NA';
          res[`subject_${index+1}_Fluency_Level_Score`] = item !== "" ? item?.fluencyLevel || 'NA' : 'NA'
        })
        return res;
    },
    async downlaodCamapignUserDetails(){
      this.downloadLoader = true;
      let query = "?campaignId="+this.id;
      const data = await CampaignController.downloadCampaignAudience(query);
      this.downloadCSV(data.result, data.fileName)
      this.downloadLoader = false;
    },
    sortCsvOnMainsNetScoreDesc(csv){
      return csv.sort((a, b) => b.Mains_net_score - a.Mains_net_score  )
    },
    formatCreatedDate(date){
       if(date == '' || date == null || date == undefined){
        return "NA"
       }
      date = new Date(date)
      return (
        String(date.getDate()).padStart(2, "0") +
        "/" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(date.getFullYear()).padStart(2, "0")
      );
    },
    convertArrayToCommaSepratedVals(data){
      if(typeof data == 'string'){
        return data;
      }else if(Array.isArray(data)) {
        return data.join(", ")
      } else {
        return 'NA'
      }
    },
    getFullName(info){
      let keys = ['firstName', 'middleName', 'lastName']
       return  keys.map((key)=> info.hasOwnProperty(key) ? `${info[key]}` : '' ).join(" ")
    
    },
     downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    async downloadQrCode() {
      // qrDownloadRef
      this.qrDownloadLoading = true;
      html2canvas(this.$refs.qrDownloadRef).then((canvas) => {
        let link = document.createElement("a");
        // link.download = 'screenshot.jpg';
        link.download = `${this.campaignName}.jpg`;
        link.href = canvas.toDataURL("image/jpeg", 0.5);
        link.click();
      });
      this.qrDownloadLoading = false;
    },

    async generateQRCode() {
      try {
        this.qrLoading = true;
        const id = this.id;
        const audienceType = this.campaignType;

        // console.log('data :', data);

        let shortCampaignLink =
          `https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=` + id;

        if (audienceType.toLowerCase() !== "teacher") {
          const campaignLink = `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${id}&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${id}&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${id}`;

          const apiRes = await axios.post(
            `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`,
            {
              longDynamicLink: campaignLink,
              suffix: {
                option: "SHORT",
              },
            }
          );

          if (apiRes?.data?.shortLink) {
            shortCampaignLink = apiRes?.data?.shortLink;
          }
        }
        // this.qrCampaignName=data.name
        this.qrCampaignLink = shortCampaignLink;
        this.qrLoading = false;
        this.qrDialog = true;
      } catch (err) {
        console.log("Error :", err);
        this.qrLoading = false;
      }
    },
    manageDialog(data) {
      console.log("debfgjwkdsbfvebdcv,br", data);
      this.scheduleInterviewDialog = data;
    },

    async copyToClipboard() {
      let shortCampaignLink =
        `https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=` +
        this.id;

      if (this.campaignType.toLowerCase() !== "teacher") {
        const campaignLink = `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${this.id}&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${this.id}&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${this.id}`;

        const apiRes = await axios.post(
          `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`,
          {
            longDynamicLink: campaignLink,
            suffix: {
              option: "SHORT",
            },
          }
        );

        if (apiRes?.data?.shortLink) {
          shortCampaignLink = apiRes?.data?.shortLink;
        }
      }

      console.log("campaignLink :", shortCampaignLink);
      navigator.clipboard
        .writeText(shortCampaignLink)
        .then(() => {
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    },

    getFinalResult(userId) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", //use name for router push
        query: {
          campaignId: this.id,
          userId: userId,
        },
      });
      // this.$router.push('/userreport')
    },

    async scheduleInterview(userData) {
      try {
        const responce = await CampaignController.getAvalableSlots(this.id);
        if (responce.data.flag) {
          var slotsData = responce?.data?.data;
          if (slotsData?.length == 0 ) {
            alert("Slots are not available for this campaign now");
          } else {
            console.log("value for schedule interview ", slotsData);
            this.currentUserDataForScheduleInterview = userData;
            this.currentUserDataForScheduleInterview.slotsData = slotsData;
            this.currentUserDataForScheduleInterview.schoolsList =
              typeof this.schoolsList == 'string'? [this.schoolsList] : this.schoolsList;
            this.currentUserDataForScheduleInterview.campaignId = this.id;
            this.scheduleInterviewDialog = true;
            this.currentUserDataForScheduleInterview.disableReschedule = false;
            if (userData.interviewDetails !== undefined) {
              this.currentUserDataForScheduleInterview.isResuduleFun = true;
              if(userData?.interViewFeedback !== undefined)
                this.currentUserDataForScheduleInterview.disableReschedule = true;

            } else {
              this.currentUserDataForScheduleInterview.isResuduleFun = false;
            }
            console.log(
              "usersgscgdsfvachjvadsycvdak",
              this.currentUserDataForScheduleInterview
            );
          }
        }
      } catch (error) {
        alert(error);
      }
    },

    // generateRandomColor() {
    //   const minRed = 255;
    //   const maxRed = 255;
    //   const minGreen = 255;
    //   const maxGreen = 0;
    //   const minBlue = 0;
    //   const maxBlue = 0;

    //   const randomRed = Math.floor(Math.random() * (maxRed - minRed + 1) + minRed);
    //   const randomGreen = Math.floor(Math.random() * (maxGreen - minGreen + 1) + minGreen);
    //   const randomBlue = Math.floor(Math.random() * (maxBlue - minBlue + 1) + minBlue);

    //   const randomColor = `rgb(${randomRed},${randomGreen},${randomBlue})`;

    //   return randomColor;
    // },

    // demoReport() {
    //   // console.log("data demo videos...???", this.dataDemoVideos)
    //   this.dataDemoVideos.sort((x, y) => x.date.localeCompare(y.date))

    //   // this.dataDemoVideos = this.dataDemoVideos[this.dataDemoVideos.length - 1]
    //   // localStorage.setItem("videoInfo", JSON.stringify(this.dataDemoVideos))
    //   // this.$store.commit("setVideoDetails", this.dataDemoVideos)

    //   const demoVideos = this.dataDemoVideos[this.dataDemoVideos.length - 1]
    //   localStorage.setItem("videoInfo", JSON.stringify(demoVideos))
    //   this.$store.commit("setVideoDetails", demoVideos)

    //   // this.$router.push("/demoreport")
    //   this.demoDialog = true
    // },

    // closeDemo() {
    //   this.demoDialog = false;
    //   this.switchResult(0);
    //   this.isSelect = 0;
    // },

    // showProc() {
    //   if (this.bubbleChartData.length > 1) {
    //     this.proctorDialog = true;
    //   }
    //   else {
    //     alert("Proctoring data not found")
    //   }
    // },

    // async generateDemoReport() {
    //   try {
    //     console.log("entered in generated report")
    //     item.loading = true;
    //     const rowData = data.dataVideosTable[item.index];
    //     console.log("row data...??", rowData)
    //     if (!rowData.avgEmotions) {
    //       const response = await VideoController.getSentiment(rowData.email, rowData.videoId, rowData.downLoadLink);
    //       console.log("Generating Report....??", response)
    //     }

    //     let cap = "";

    //     if ((!rowData.caption) && (rowData.caption !== "")) {
    //       const response = await VideoController.generateTranscript(rowData.email, rowData.videoId, rowData.savedVideoName)

    //       if (response && response?.flag) {
    //         cap = response.caption
    //         await VideoController.speechAnalysis(rowData.email, rowData.videoId, cap)
    //       }
    //       else {
    //         console.log('retrying...1')
    //         const res2 = await VideoController.generateTranscript(rowData.email, rowData.videoId, rowData.savedVideoName)

    //         cap = res2.caption
    //         await VideoController.speechAnalysis(rowData.email, rowData.videoId, cap)
    //       }
    //     }
    //     else {
    //       cap = rowData.caption
    //     }

    //     if (rowData.caption && !rowData.audioAnalysisReport) {
    //       const response = await VideoController.speechAnalysis(rowData.email, rowData.videoId, cap);
    //     }

    //     console.log("here1")

    //     item.loading = false;

    //   } catch (err) {
    //     console.log("here2")
    //     item.loading = false;
    //     console.log(err);
    //     alert('Error :', err);
    //   }
    // },

    // async checkDemoReportStatus() {
    //   const temp = this.dataDemoVideos[item.index].avgEmotions && this.dataDemoVideos[item.index].caption && this.dataDemoVideos[item.index].audioAnalysisReport

    //   if (!temp) {
    //     item.loading = true;
    //   }

    //   return (this.dataDemoVideos[item.index].avgEmotions && this.dataDemoVideos[item.index].caption && this.dataDemoVideos[item.index].audioAnalysisReport)
    // },

    goBack() {
      // this.reportDialog = false;
      this.$router.back();
    },

    // hasGenerated(event) {
    //   console.log("generated pdf", event)
    // alert("PDF generated successfully!");
    // },

    onProgress(event) {
      console.log(`progress data ${event}`);
      if (event === 100) {
        this.downloadLoader = false;
      }
    },

    // generateReport() {
    //   this.downloadLoader = true;
    //   this.$refs.html2Pdf.generatePdf();
    // },

    // downloadPdf(){
    //   const pdf=new jsPDF()
    //   const content=this.$refs.downloadableContent.innerHTML
    //   pdf.html(content)
    //   pdf.save("test.pdf")
    //   console.log("entered")

    //   const element=this.$refs.downloadableContent
    //   console.log("elemnt", element)
    //   console.log("innerhtml", element.innerHTML)
    //   html2pdf(element,{
    //     margin:1,
    //     filename: "test..pdf",
    //     image: { type: "jpeg", quality:0.98},
    //     html2canvas: { dpi: 192, letterRendering: true},
    //     jspdf: {unit: "in", format: "letter", orientation: "portrait"}
    //   })

    //   this.$refs.html2Pdf.generatePdf();
    // },

    // updateChart(){
    //   let testType="SCREENING"
    //   if (this.isSelect==1){
    //     testType="MAINS"
    //   }
    //   const testRef = doc(
    //     db,
    //     "proctoredtests",
    //     this.id,
    //     this.selectedUser,
    //     testType
    //   );

    //   console.log("Path...>>>", this.id, this.selectedUser, this.testType);
    //   onSnapshot(testRef, (snapshot)=>{
    //     // console.log("entered");
    //     if (!snapshot.data){
    //       console.log("entered");
    //       return;
    //     }
    //     console.log("testRef Data...>>>>",snapshot.data());
    //   })
    // },

    // secondsToTime(seconds) {
    //   var hours = Math.floor(seconds / 3600);
    //   var minutes = Math.floor((seconds % 3600) / 60);
    //   var seconds = seconds % 60;
    //   return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    // },

    // addDataToChart() {
    //   this.bubbleChartData.splice(1);
    //   this.bubbleChartOptions.hAxis.viewWindow.max = parseInt(this.timeToMinutes(this.userProctoringActivities[this.userProctoringActivities.length - 1].time) - this.timeToMinutes(this.userProctoringActivities[0].time)) + 1

    //   this.bubbleChartOptions.vAxis.viewWindow.max = 1;

    //   for (const act of this.userProctoringActivities) {

    //     // this.bubbleChartData.push([act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time), act.time, act.activity, act.ques])
    //     this.bubbleChartData.push([act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time), act.ques, act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time)])

    //     this.bubbleChartOptions.vAxis.viewWindow.max = Math.max(this.bubbleChartOptions.vAxis.viewWindow.max, act.ques + 1);
    //   }
    // },

    timeToMinutes(timeString) {
      var timeParts = timeString.split(":");
      var hours = parseInt(timeParts[0]);
      var minutes = parseInt(timeParts[1]);
      var seconds = parseInt(timeParts[2]);
      return (hours * 3600 + minutes * 60 + seconds) / 60;
    },

    openLeagueDialog(skillsList, firstName, lastName) {
      this.userName = firstName + " " + lastName;
      this.coreSkillsList = skillsList;
      this.leagueDialog = true;
    },

    clearFilter() {
      this.selectedClusterFilters = [];
      this.selectedBoardsFilter = [];
      this.fetchCampaignAudience();
      this.appliedFilter = false;
      this.filterCount = 0;
    },

    getSelectedSkillData(skill, index) {
      // console.log("skill :", skill)
      // console.log("selectedResult :", this.selectedResult)
      this.currenrSkillIndex = index;
      this.selectedSkill = skill;
      this.progressLevel =
        (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedClusterFilters.length > 0) {
        this.filterCount++;
      }
      if (this.selectedBoardsFilter.length > 0) {
        this.filterCount++;
      }
    },

    logout() {
      this.clearPaginationStates();
      AuthService.logout();
      this.$router.push("/login");
    },

    // switchResult(index) {
    //   // console.log("index" , index)
    //   if (index != 2) {
    //     this.totalScore = 0;
    //     this.maxMarks = 0;
    //     this.selectedResult = this.resultData[index];
    //     // console.log("selectedResult....????", this.selectedResult)
    //     this.maxTime = this.selectedResult.maximumTime / 60;
    //     this.userTime = (this.selectedResult.timeTakenByUser / 60).toFixed(2);
    //     if (this.selectedResult == null) {
    //       alert("Test not started yet");
    //       this.switchResult(0);
    //       this.isSelect = 0;
    //     } else {
    //       this.selectedSkill = this.resultData[index].skills[0];
    //       // console.log("selectedSkills...???", this.selectedSkill)
    //       this.progressLevel = (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100
    //       this.selectedResult.skills.forEach((skill) => {
    //         this.totalScore = this.totalScore + skill.score;
    //         this.maxMarks += skill.maximumMarks;

    //       });

    //     }
    //   }

    //   else if (index === 2) {
    //     if (this.dataDemoVideos?.length > 0) {
    //       this.demoReport();
    //     } else {
    //       alert("Demo video not uploaded");
    //       this.switchResult(0);
    //       this.isSelect = 0;
    //     }
    //   }

    // },

    async getResult(data) {
      const response = await CampaignController.getResult(data.userId, this.id);
      if (response.status == 200) {
        if (response.data.length == 0) {
          alert("User test not started");
        } else {
          this.$router.push(`/testreport/${this.id}/${data.userId}`);
        }
      } else {
        alert(response.data.error);
      }
    },

    // async getResult(data) {
    //   // console.log("Seeing here all the data...????", data, this.id);
    //   // console.log("data...>>>", data.userId, this.id)
    //   // this.btnLoading = true;

    //   const response = await CampaignController.getResult(data.userId, this.id);
    //   // console.log("questions", response);
    //   if (response.status == 200) {
    //     if (response.data.data.length == 0) {
    //       alert("User test not Started");
    //       // this.btnLoading = false;
    //     } else {
    //       this.resultData = response.data.data;
    //       // const dataToStore = JSON.stringify(this.resultData);
    //       // localStorage.setItem('resultData', dataToStore);
    //       // this.reportDialog = true;
    //       this.$router.push(`/testreport/${this.id}/${data.userId}`)
    //       this.switchResult(0);
    //       this.selectedUser = data.userId;
    //       this.selectedUserName = data.personalInfo.firstName + " " + data.personalInfo.middleName + " " + data.personalInfo.lastName
    //       this.bubbleChartOptions.title = this.selectedUserName
    //       // console.log("this.selectedUser...>>>", this.selectedUser)
    //       // console.log("this.id...>>>", this.id)

    //       const videoRef = collection(db, "campaigns", this.id, "audience", data.userId, "demovideos");
    //       // console.log("demo video reference..??", videoRef)

    //       onSnapshot(videoRef, async (videoSnapshot) => {

    //         this.dataDemoVideos.splice(0)

    //         // console.log("docs :::", videoSnapshot.size)

    //         videoSnapshot.forEach((v) => {
    //           const videoData = v.data();
    //           const videoId = v.id;
    //           this.dataDemoVideos.push({ name: data.personalInfo.firstName, email: data.personalInfo.email, videoId: videoId, ...videoData })
    //         })

    //         // console.log("data demo videos after video snapshot...??", this.dataDemoVideos)
    //       })

    //       const testRef = doc(
    //         db,
    //         "proctoredtests",
    //         this.id,
    //         this.selectedUser,
    //         "SCREENING"
    //       );

    //       const test = await getDoc(testRef);
    //       this.proctoringData = {
    //         "Copy/Paste": 0,
    //         "Exit Fullscreen": 0,
    //         Screenshot: 0,
    //         "Switch Tab/Browser": 0,
    //       };

    //       if (test.exists()) {
    //         // console.log(test.data())
    //         this.userProctoringActivities.splice(0)
    //         this.cumulativeProctoringData.splice(1)
    //         for (const temp of test.data().activities) {
    //           if (temp.activity in this.proctoringData) {
    //             // console.log(temp)
    //             this.proctoringData[temp.activity] += 1;
    //           }
    //           this.userProctoringActivities.push(temp);

    //           if (temp.activity !== "Started" && temp.activity !== "Completed") {
    //             let flag = true;
    //             for (let i = 0; i < this.cumulativeProctoringData.length; i++) {
    //               // if activitiy already exists
    //               if (this.cumulativeProctoringData[i][0] === temp.activity) {
    //                 this.cumulativeProctoringData[i][1] += 1
    //                 flag = false;
    //               }
    //             }
    //             // if a new activity is found
    //             if (flag) {
    //               this.cumulativeProctoringData.push([temp.activity, 1, this.generateRandomColor()]);
    //             }

    //           }

    //         }
    //         this.addDataToChart();

    //         // console.log("cumulativeProcChartData :", this.cumulativeProctoringData);

    //         // creating cumulative data
    //         // for (const act of this.userProctoringActivities){
    //         //   if (act.activity!=="Started" && act.activity!=="Completed"){
    //         //     let flag=true;
    //         //     for (let i=0; i<this.cumulativeProctoringData.length; i++){
    //         //       // if activitiy already exists
    //         //       if (this.cumulativeProctoringData[i][0]===act.activity){
    //         //         this.cumulativeProctoringData[i][1]+=1
    //         //         flag=false;
    //         //       }
    //         //     }
    //         //     // if a new activity is found
    //         //     if (flag){
    //         //       this.cumulativeProctoringData.push([act.activity, 1]);
    //         //     }

    //         //   }
    //         // }

    //         // console.log("cumulative Data :", this.cumulativeProctoringData);

    //       }

    //       // this.btnLoading = false;
    //     }
    //   } else {
    //     alert(response.data.error);
    //   }

    //   // console.log("data here", data);
    //   // if (data.result == null) {
    //   //   alert("User test not Started");
    //   //   this.btnLoading = false;
    //   // } else {
    //   //   (this.reportDialog = true),
    //   //     (this.questions = data.result.questions),
    //   //     this.getSkillsArray(data.result.questions),
    //   //     (this.resultData = data.result);
    //   //   this.btnLoading = false;

    //   //   // console.log("questions", data);
    //   // }

    //   //  "reportDialog = true; questions= item.questions"
    // },

    searchData(search) {
      let user = this.preSearchUser;
      let searchString = this.removeSpaces(search);
      let usersList = user.filter((item) => {
        let searchByName = this.removeSpaces(item.personalInfo?.firstName +  item.personalInfo?.lastName)
        let searchByEmail = this.removeSpaces(item.personalInfo.contactInfo?.email)
        return  new RegExp(searchString, "i").test(searchByName) ||  new RegExp(searchString, "i").test(searchByEmail)
      }
      );
      this.users = this.calculateUsersForLeague(usersList);
    },
    removeSpaces(str = ""){
      return str.replace(/\s/g, '');
    },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    filterByStatus(mainArray, statusToFilter) {
      return mainArray.filter((item) =>
        item.campaignStatus.some(
          (nestedItem) => nestedItem.status === statusToFilter
        )
      );
    },
    createStepperData(data){
      data?.forEach((item, index)=>{
        let obj = {invitation: { name:'mdi-check-circle', iconColor: 'green'}, 
                 screening:{  },
                 mains:{ },
                 demo:{ },
                 interview:{}}
        let status = item?.campaignStatus
        let userType = item?.userType || "Undefined";
        if(userType?.toLowerCase() === "teacher"){
        if(status[1]?.status === "MAINS STARTED"){
          // For Teachers we are storing mains data in screening as it is rendering screnning data
          obj['screening'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        }
        else{
          obj['screening'] = this.getStepperIconAndColor("","")
        }
      }
        else{
        obj['screening'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        obj['mains'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[3]?.status), this.getPassFailGenericVal(status[4]?.status))
        obj['demo'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[5]?.status), this.getPassFailGenericVal(status[6]?.status))
        obj['interview'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[7]?.status), this.getPassFailGenericVal(status[8]?.status))
        }
        this.stepper.set(index, obj)
        obj = {}
      })
    },
    getStepperIconAndColor(prevStatus = '', curStatus = ''){
      let map = new Map([
        ['started', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['startedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['startedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failed',{name:'mdi-circle-double', iconColor: 'grey'}],
        ['failedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['passed', {name:'mdi-circle-double', iconColor: 'grey'}],
        ['passedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['passedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['', {name:'mdi-circle-double', iconColor: 'grey'}],

      ])
     let key = prevStatus+curStatus.trim()
      return map.get(key)

    },
    getPassFailGenericVal(status){
      if(status !== null && status !== undefined && status !== ''){
        return status.split(' ')[1]?.toLowerCase().trim()
      } else{   return ''}
  
    },
    addDemoInterviewScore(){
     this.users =  this.users.map((user)=>{
        if(user?.demoStatus){
          if(user.userType !== 'Teacher' && user.campaignStatus[user.campaignStatus.length-1].status == 'MAINS PASSED') {
            user.enableScheduleInterview = true
          }
          if(user.campaignStatus.filter((item)=> item.status.includes('DEMO')).length == 0){
            user.campaignStatus.push({status: 'DEMO STARTED'})
           user.campaignStatus.push({status: 'DEMO PASSED'}) 
          }
                  
        }
        if(user?.interViewFeedback){
          if(user.campaignStatus.filter((item)=> item.status.includes('INTERVIEW')).length == 0){
            user.campaignStatus.push({status: 'INTERVIEW STARTED'})
           user.campaignStatus.push({status: 'INTERVIEW PASSED'})
          }       
        }
        return user
      })
     
    },
    async fetchCampaignAudience() {
      this.dataTableLoading = true;
      let query = "?campaignId="+this.id+"&search="+this.search+"&itemsPerPage="+this.pageSize+"&page="+this.page
      if(this.selectedClusterFilters.length > 0)
      {
        this.isFilterLoading = true;
      }
      if(this.selectedBoardsFilter.length > 0){
        this.isFilterLoading = true;
      }
      const audienceData = await CampaignController.getCampaignAudience(query, this.selectedClusterFilters, this.selectedBoardsFilter);
      this.preSearchUser = audienceData.result.users
      this.totalUsers = audienceData.result.totalUsers;
      console.log(this.totalUsers);
      let preUsers = this.preSearchUser
      // console.log(preUsers);
      this.users = this.preSearchUser;
      this.users = this.calculateUsersForLeague(preUsers);
          this.users=this.users.map(user=> 
            {
              if(user.coreSkillsSubject.length > 0 && user.coreSkillsSubject != undefined){
                  let leagueArray = user.coreSkillsSubject.map(subject => subject.league );
                  user['NoLeague'] = leagueArray.every((item)=>{return item=='Not Eligible'});
              }
              return user;
            }
          )
          this.addDemoInterviewScore()
          this.createStepperData(this.users)
      this.dataTableLoading = false;
      this.isFilterLoading = false;
      this.filterDialog = false;
    },
    async setupSnapshotListenerFunnel(id) {
      this.preloader = false;
      this.dataTableLoading = false;

    const dashboardTeachersRef = doc(
    db,
    "dashboards",
    "management-dashboard-001",
    "campaigns",
    id
  );

  try {
    // Check if the document exists
    const docSnapshot = await getDoc(dashboardTeachersRef);

    if (!docSnapshot.exists()) {
      // If the document doesn't exist, call fetchCampaignAudience(id)
      await this.fetchCampaignAudienceOld(id);
    } else {

   onSnapshot(dashboardTeachersRef, (snapshot) => {
    this.chartData = this.emptyChartData;

      const resultData = snapshot.data();

      if (
        resultData.invited === undefined ||
        resultData.screeningStarted === undefined ||
        resultData.screeningPassed === undefined ||
        resultData.screeningFailed === undefined ||
        resultData.mainsStarted === undefined ||
        resultData.mainsPassed === undefined ||
        resultData.mainsFailed === undefined
      ) {
        // Handle missing data
      } else {
        this.chartData = resultData;
        this.invited = resultData.invited;
        this.screeningStarted = resultData.screeningStarted;
        this.screeningPassed = resultData.screeningPassed;
        this.mainsStarted = resultData.mainsStarted;
        this.mainsPassed = resultData.mainsPassed;
        this.screeningFailed = resultData.screeningFailed;
        this.mainsFailed = resultData.mainsFailed;
        this.apraisals = resultData.mainsPassed;
        this.pip = resultData.mainsFailed;
        this.tbd = resultData.screeningFailed;
        this.chartData.invited = resultData.invited;
        this.chartData.screeningStarted = resultData.screeningStarted;
        this.chartData.screeningPassed = resultData.screeningPassed;
        this.chartData.mainsStarted = resultData.mainsStarted;
        this.chartData.mainsPassed = resultData.mainsPassed;
        this.chartData.screeningFailed = resultData.screeningFailed;
        this.chartData.mainsFailed = resultData.mainsFailed;
        this.chartData.demoSubmitted = resultData.demoSubmitted;
        this.chartData.interviewSubmitted = resultData.interviewSubmitted;
        this.chartData.apraisals = resultData.mainsPassed;
        this.chartData.pip = resultData.mainsFailed;
        this.chartData.tbd = resultData.screeningFailed;
      }
  });
}
  } catch (error) {
    console.error("Error checking document existence or setting up snapshot:", error);
  }
},

async fetchCampaignAudienceOld(id) {
        const campaignRef = collection(db, "campaigns", id, "audience");
        const campDetailsRef = doc(db, "campaigns", id);

        const docSnap = await getDoc(campDetailsRef);

        if (docSnap.exists()) {
          // console.log("camp details here..?", docSnap.data())
          const campDetails = docSnap.data();
          this.campName = campDetails.name;
          this.campaignDetails = campDetails
          // console.log("name of the campaign..?", this.campName)
        }

        // console.log("heyyylooooo");
        onSnapshot(campaignRef, (snapshot) => {
          this.preloader = false;
          this.dataTableLoading = false;
          this.users = [];
          this.preSearchUser = [];
          var preUsers = [];
          if (snapshot.empty) {
          } else {
            this.count = snapshot.docs.length;
            snapshot.docs.forEach(async (doc) => {
              // this.users.push(doc.data());
              this.preSearchUser.push(doc.data());
              preUsers.push(doc.data());
            });
            this.users = this.calculateUsersForLeague(preUsers);
            this.users = this.users.map(user => {
              if (user.coreSkillsSubject.length > 0 && user.coreSkillsSubject != undefined) {
                let leagueArray = user.coreSkillsSubject.map(subject => subject.league);
                user['NoLeague'] = leagueArray.every((item) => { return item == 'Not Eligible' });
              }
              return user;
            }
            )
            this.addDemoInterviewScore()
            this.createStepperData(this.users)
            this.csvData = this.users
            // console.log("preUsers", preUsers);

            this.chartData = this.filterByStatus(preUsers, "INVITED");

            this.invited = this.filterByStatus(preUsers, "INVITED").length;
            this.screeningStarted = this.filterByStatus(
              preUsers,
              "SCREENING STARTED"
            ).length;
            this.screeningPassed = this.filterByStatus(
              preUsers,
              "SCREENING PASSED"
            ).length;
            this.mainsStarted = this.filterByStatus(
              preUsers,
              "MAINS STARTED"
            ).length;
            this.mainsPassed = this.filterByStatus(
              preUsers,
              "MAINS PASSED"
            ).length;
            this.screeningFailed = this.filterByStatus(
              preUsers,
              "SCREENING FAILED"
            ).length;
            this.mainsFailed = this.filterByStatus(
              preUsers,
              "MAINS FAILED"
            ).length;

            this.chartData.invited = this.filterByStatus(
              preUsers,
              "INVITED"
            ).length;
            this.chartData.screeningStarted = this.filterByStatus(
              preUsers,
              "SCREENING STARTED"
            ).length;
            this.chartData.screeningPassed = this.filterByStatus(
              preUsers,
              "SCREENING PASSED"
            ).length;
            this.chartData.mainsStarted = this.filterByStatus(
              preUsers,
              "MAINS STARTED"
            ).length;
            this.chartData.mainsPassed = this.filterByStatus(
              preUsers,
              "MAINS PASSED"
            ).length;
            this.chartData.screeningFailed = this.filterByStatus(
              preUsers,
              "SCREENING FAILED"
            ).length;
            this.chartData.mainsFailed = this.filterByStatus(
              preUsers,
              "MAINS FAILED"
            ).length;
            this.chartData.demoSubmitted = this.filterByStatus(preUsers, "DEMO PASSED").length;
            this.chartData.interviewSubmitted = this.filterByStatus(preUsers, "INTERVIEW STARTED").length;
            this.apraisals = this.filterByStatus(preUsers, "MAINS PASSED").length;
            this.pip = this.filterByStatus(preUsers, "MAINS FAILED").length;
            this.tbd = this.filterByStatus(preUsers, "SCREENING FAILED").length;
          }
          // console.log("this.chartData", this.chartData)
        });

        // onSnapshot(campDetailsRef, (snapshot)=>{

        // })

        // const response = await CampaignController.fetchCampaignAudience(id);
        // if (response.data.flag) {
        //   // this.preloader = false;
        //   // this.count = response.data.count;
        //   // this.dataTableLoading = false;
        //   if (response.data.audience != undefined) {
        //     this.users = response.data.audience;
        //     this.preSearchUser = response.data.audience;
        //     var preUsers = response.data.audience == null ? [] : response.data.audience;
        //     this.chartData.invited = this.filterByStatus(preUsers, 'INVITED').length;
        //     this.invited = this.filterByStatus(preUsers, 'INVITED').length;
        //     this.screeningStarted = this.filterByStatus(preUsers, 'SCREENING STARTED').length;
        //     this.screeningPassed = this.filterByStatus(preUsers, 'SCREENING PASSED').length;
        //     this.mainsStarted = this.filterByStatus(preUsers, 'MAINS STARTED').length;
        //     this.mainsPassed = this.filterByStatus(preUsers, 'MAINS PASSED').length;
        //     this.screeningFailed = this.filterByStatus(preUsers, 'SCREENING FAILED').length;
        //     this.mainsFailed = this.filterByStatus(preUsers, 'MAINS FAILED').length;
        //     this.chartData.screeningStarted = this.filterByStatus(preUsers, 'SCREENING STARTED').length;
        //     this.chartData.screeningPassed = this.filterByStatus(preUsers, 'SCREENING PASSED').length;
        //     this.chartData.mainsStarted = this.filterByStatus(preUsers, 'MAINS STARTED').length;
        //     this.chartData.mainsPassed = this.filterByStatus(preUsers, 'MAINS PASSED').length;
        //     this.chartData.screeningFailed = this.filterByStatus(preUsers, 'SCREENING FAILED').length;
        //     this.chartData.mainsFailed = this.filterByStatus(preUsers, 'MAINS FAILED').length;
        //     this.apraisals = this.filterByStatus(preUsers, 'MAINS PASSED').length;
        //     this.pip = this.filterByStatus(preUsers, 'MAINS FAILED').length;
        //     this.tbd = this.filterByStatus(preUsers, 'SCREENING FAILED').length;
        //   }
        // } else {
        //   alert(response.data.error);
        // }
      },

    calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },
    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();

      if (response.status == 200) {
        // console.log(response);
        if (response.data.clusters.length > 0) {
          this.clusters = response.data.clusters;
        } else {
          this.clusters = [];
        }
        // console.log("afkdsbxbfabsbdj,a", this.clusters);
      } else {
        alert(response.data.error);
      }
    },
    async getBoards() {
      const response = await BoardController.getBoardsByPagination();
      if (response.status == 200) {
        this.count = response.data.count;
        if (response.data.boards.length > 0) {
          //  console.log("vall" , response.data.boards);
          this.boardsData = response.data.boards;
        }
      } else {
        alert(response.data.error);
      }
    },
    async clearUserAssessment(userId){
      this.restartAssessmentLoader = true;
      const response = await CampaignController.clearUserAssessment(userId, this.id);
      this.restartAssessmentDialog = false;
      this.restartAssessmentLoader = false;
      if(response.flag == true){
        this.clearUserAssessmentSuccessSnackbar = true;
      } else {
        alert(response.result);
        this.clearUserAssessmentFailureSnackbar = true;
      }
    },

    // async recommendedJobseekerCountListener() {
    //   try {
    //     const campaignRef = query(collection(db, "campaigns"), where("id", "==", this.id));

    //     // Real-time listener for campaign documents
    //     onSnapshot(campaignRef, async (snapshot) => {
    //       this.recommendedJobseekerCount = 0; // Reset count at the start of each snapshot

    //       // Iterate over all campaign documents
    //       for (const doc of snapshot.docs) {
    //         const campaignData = doc.data();

    //         // Ensure it's a valid job seeker audience
    //         const audienceType = campaignData?.audienceType?.toLowerCase();
    //         if (audienceType === "job_seeker" || audienceType === "jobseeker" || audienceType === "job seeker") {
    //           try {
    //             const jobSeekerSchoolCluster = campaignData.clusters ?? [];
    //             const jobSeekerRef = collection(db, "campaigns", doc.id, "audience"); // Use correct ref to audience sub-collection
    //             const jobSeekDocs = await getDocs(jobSeekerRef);

    //             // Loop through job seeker documents
    //             for (const d of jobSeekDocs.docs) {
    //               const jobSeekerData = d.data();
    //               const schoolName = this.$store.state.role.schools;
    //               const clusterName = this.$store.state.role.clusterName;
    //               const jobSeekerSchoolName = jobSeekerData.teachingInterests?.schools ?? [];

    //               // Apply the filter logic
    //               if (
    //                 (clusterName === "All Clusters" && schoolName === "All") ||
    //                 (clusterName !== "All Clusters" &&
    //                   schoolName === "All" &&
    //                   ((typeof jobSeekerSchoolCluster === "string" && jobSeekerSchoolCluster === clusterName) ||
    //                     (Array.isArray(jobSeekerSchoolCluster) && jobSeekerSchoolCluster.includes(clusterName)))) ||
    //                 (clusterName !== "All Clusters" &&
    //                   ((typeof jobSeekerSchoolName === "string" && jobSeekerSchoolName === schoolName) ||
    //                     (Array.isArray(jobSeekerSchoolName) && jobSeekerSchoolName.includes(schoolName))))
    //               ) {
    //                 // Condition to determine recommendation eligibility
    //                 if (
    //                   jobSeekerData?.campaignStatus?.length === 5 &&
    //                   jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" &&
    //                   jobSeekerData.campaignStatus[4].status === "MAINS PASSED" &&
    //                   jobSeekerData?.coreSkillsSubject?.length > 0 &&
    //                   jobSeekerData?.interViewFeedback?.length > 0
    //                 ) {
    //                   let netScoreMains = 0;
    //                   let netScoreInterview = 0;

    //                   // Calculate Mains Score
    //                   jobSeekerData.coreSkillsSubject.forEach((dt) => {
    //                     if (dt.league !== "Not Eligible") {
    //                       netScoreMains += parseFloat(dt.finalNetScore || 0);
    //                     }
    //                   });

    //                   // Calculate Interview Score
    //                   jobSeekerData.interViewFeedback.forEach((interview) => {
    //                     netScoreInterview += parseFloat(interview.avgScore || 0);
    //                   });

    //                   // Calculate averages
    //                   netScoreMains = netScoreMains / jobSeekerData.coreSkillsSubject.length;
    //                   netScoreInterview = netScoreInterview / jobSeekerData.interViewFeedback.length;

    //                   // Final Recommendation Score
    //                   const netFinalRecommendationScore = (netScoreMains + netScoreInterview) / 2;

    //                   // Increment count if the score meets criteria
    //                   if (netFinalRecommendationScore >= 0) {
    //                     this.recommendedJobseekerCount++;
    //                   }
    //                 }
    //               }
    //             }
    //           } catch (error) {
    //             console.error("Error fetching job seeker data:", error);
    //           }
    //         }
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Error setting up jobseeker listener:", error);
    //   }
    // },
    
    async recommendedJobseekerCountListener() {
      const campaignDocRef = doc(db, "campaigns", this.id);
      onSnapshot(campaignDocRef, async (docSnapshot) => {
        this.recommendedJobseekerCount = 0;
        if (docSnapshot.exists()) {
          const campaignData = docSnapshot.data();
          // Check if the document is for job seekers
          if (campaignData.audienceType.toLowerCase() === "job_seeker" || 
              campaignData.audienceType.toLowerCase() === "jobseeker" || 
              campaignData.audienceType.toLowerCase() === "job seeker") {
            try {
              const jobSeekerSchoolCluster = campaignData.clusters ?? [];
    
              const jobSeekerRef = collection(campaignDocRef, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);
    
              // Process each job seeker document in the audience sub-collection
              for (const d of jobSeekDocs.docs) {
                const jobSeekerData = d.data();  
    
                const schoolName = this.$store.state.role.schools;
                const clusterName = this.$store.state.role.clusterName;
                const jobSeekerSchoolName = jobSeekerData.teachingInterests?.schools ?? [];
    
                if (
                  (clusterName === "All Clusters" && schoolName === "All") ||
                  (clusterName !== "All Clusters" && schoolName === "All" && 
                    ((typeof jobSeekerSchoolCluster === 'string' && jobSeekerSchoolCluster === clusterName) || 
                    (Array.isArray(jobSeekerSchoolCluster) && jobSeekerSchoolCluster.includes(clusterName)))) ||
                  (clusterName !== "All Clusters" && 
                    ((typeof jobSeekerSchoolName === 'string' && jobSeekerSchoolName === schoolName) || 
                    (Array.isArray(jobSeekerSchoolName) && jobSeekerSchoolName.includes(schoolName))))
                ) {
                  if (jobSeekerData?.campaignStatus?.length === 5 && 
                      jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && 
                      jobSeekerData.campaignStatus[4].status === "MAINS PASSED" && 
                      jobSeekerData?.coreSkillsSubject?.length > 0 && 
                      jobSeekerData?.interViewFeedback?.length > 0) {
                    let netScoreMains = 0;
                    let netScoreInterview = 0;
                    for (const dt of jobSeekerData.coreSkillsSubject) {
                      if (dt.league !== "Not Eligible") {
                        try {
                          netScoreMains += parseFloat(dt.finalNetScore);
                        } catch (error) {
                          console.error("Error parsing mains score:", error);
                        }
                      }
                    }
    
                    for (const interview of jobSeekerData.interViewFeedback) {                    
                      try {
                        netScoreInterview += parseFloat(interview.avgScore);                      
                      } catch (error) {
                        console.error("Error parsing interview score:", error);
                      }                    
                    }
                    netScoreMains /= jobSeekerData.coreSkillsSubject.length;
                    netScoreInterview /= jobSeekerData.interViewFeedback.length;
                    const netFinalRecommendationScore = (netScoreMains + netScoreInterview) / 2;
                    if (netFinalRecommendationScore >= 0) {
                      this.recommendedJobseekerCount += 1;
                    }
                  }
                }
              }
            } catch (error) {
              console.error("Error processing campaign document:", error);
            }
          }
        } else {
          console.log("Campaign document does not exist");
        }
      });
    },

    async goToRecommendedForHiring() {
      try {
        this.$router.push({
          path: "/jobseekerstbd",
          name: "jobseekerstbd",
          query: {
            id: this.id,
          },
        });
      } catch (error) {
        console.error("Error navigating to recommended job seekers:", error);
      }
    },
    async goToJobSeekersInterviewed() {
      try {
        this.$router.push({
          path: "/jobseekerspip",
          name: "jobseekerspip",
          query: {
            id: this.id,
          },
        });
      } catch (error) {
        console.error("Error navigating to recommended job seekers:", error);
      }
    },
    async goToJobSeekersAppraisal() {
      try {
        this.$router.push({
          path: "/jobseekersappraisal",
          name: "jobseekersappraisal",
          query: {
            id: this.id,
          },
        });
      } catch (error) {
        console.error("Error navigating to recommended job seekers:", error);
      }
    },
    filterAllAudiances(value, search, item) {
      this.dataTableLoading = true;
      if (item.personalInfo.contactInfo.email.toLowerCase().includes(search.toLowerCase()) || item.personalInfo.firstName.toLowerCase().includes(search.toLowerCase()) || item.personalInfo.lastName.toLowerCase().includes(search.toLowerCase())) {
        this.dataTableLoading = false;
        return true;
      }
      this.dataTableLoading = false;
      return false;
    },
  },

  created() {
    // this.setupSnapshotListenerFunnel();
    this.id = this.$route.query.id;
    this.fetchAllClusters();
    this.getBoards();
    // this.getResult();
   
  },
};
</script>
<style scoped>
.cardBorder {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin: 0px;
}

.mdi-chevron {
  font-size: 24px !important;
  /* Set the desired size for the icon */
}

.rounded-table {
  border-radius: 8px 8px 0px 0px !important;
}

.viewTestProctoringBox {
  border: 2px solid black;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
}

::v-deep .v-data-table-header th {
  font-weight: bold;      /* Make headers bold */
  font-size: 10px;        /* Set a smaller font size */
  color: #333;   
}
</style>